<template>
  <div class="coworker">
    <div class="coworker__image-container">
      <img
        :src="getImagePath(coworker.picturePath)"
        :alt="coworker.name"
        class="coworker__image"
      />
    </div>

    <div class="coworker__information">
      <h3 class="coworker__header">
        {{ coworker.name }}
      </h3>
      <span class="coworker__role">{{ coworker.role }}</span>
      <div class="coworker__contact-icons">
        <a
          class="coworker__contact-icon"
          :href="`mailto:${coworker.emailAddress}@${coworker.emailhost}`"
        >
          <img
            src="@/assets/images/contact-icons/quendrox-icon-email.png"
            :alt="`Quendrox ${coworker.name} email`"
          />
        </a>
        <a class="coworker__contact-icon" :href="coworker.linkedin">
          <img
            src="@/assets/images/contact-icons/quendrox-icon-linked-in.png"
            :alt="`Quendrox ${coworker.name} linkedin`"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Coworker",
  props: {
    coworker: {
      name: String,
      email: String,
      linkedin: String,
      picturePath: String
    },
    imageLeft: Boolean
  },
  methods: {
    getImagePath: function(assetPath) {
      return require(`@/assets/${assetPath}`);
    }
  }
};
</script>

<style scoped lang="scss">
.coworker {
  display: flex;
  flex-direction: column;
  width: 250px;
  text-align: center;

  border: 1px solid var(--light-accent);
  border-radius: 10px;
  padding: 10px;
  background-color: var(--light-shades-faded);

  &__image-container {
    text-align: center;
  }

  &__image {
    width: 100%;
    max-width: 200px;
  }

  &__information {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  &__header {
    margin-bottom: 10px;
  }

  &__role {
    margin-bottom: 10px;
    font-style: italic;
  }

  &__contact-icons {
    display: flex;
    justify-content: center;
  }

  &__contact-icon {
    display: flex;
    align-items: center;
    margin: 0 5px 0 5px;
    border-radius: 5px;
    background-color: var(--main-brand-color);

    &:hover {
      background-color: var(--dark-accent);
    }
  }
}
</style>
