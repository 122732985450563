<template>
  <section class="readFriendlyLayout">
    <h2>Contact</h2>
    <div>
      <Coworkers />
      <ContactForm />
    </div>
  </section>
</template>

<script>
import Coworkers from "../components/contact/Coworkers.vue";
import ContactForm from "../components/contact/ContactForm.vue";

export default {
  name: "Contact",
  components: {
    Coworkers,
    ContactForm
  }
};
</script>

<style scoped lang="scss"></style>
