<template>
  <section>
    <h2>Send a message</h2>
    <form class="contact-form" v-on:submit.prevent="sendEmail()">
      <label id="formName" for="name">Name:</label>
      <input
        type="text"
        id="name"
        name="name"
        v-model="contactForm.sender.name"
        required
        class="contact-form__input"
      />

      <label id="formEmail" for="email">Email:</label>
      <input
        type="email"
        id="email"
        name="email"
        v-model="contactForm.sender.emailAddress"
        required
        class="contact-form__input"
      />

      <label id="formSubject" for="subject">Subject:</label>
      <input
        type="text"
        id="subject"
        name="subject"
        v-model="contactForm.subject"
        required
        class="contact-form__input"
      />

      <label id="formMessage" for="message">Message:</label>
      <textarea
        id="message"
        name="message"
        rows="3"
        v-model="contactForm.message"
        required
        class="contact-form__input"
      ></textarea>

      <button
        id="formSubmit"
        type="submit"
        name="submit"
        class="contact-form__button"
        title="Send message"
        :disabled="isSendingMessage"
      >
        <img
          src="@/assets/images/contact-icons/quendrox-send.png"
          alt="send-message"
        />
      </button>
    </form>
    <div
      v-if="isSendMessageOperationCompleted"
      v-bind:class="[
        isMessageSentSuccessfully ? 'success' : 'failure',
        'snackbar'
      ]"
    >
      {{
        isMessageSentSuccessfully
          ? "Thank you for your message!"
          : "Unable to send message. Please try again later or use another contact method."
      }}
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactForm",
  data() {
    return {
      contactForm: {
        sender: {
          name: "",
          emailAddress: ""
        },
        recipient: {
          organisation: "Quendrox",
          name: ""
        },
        subject: "",
        message: ""
      },
      response: null,
      messageSending: false,
      sendMessageOperationCompleted: false,
      messageSuccess: false
    };
  },
  methods: {
    async sendEmail() {
      this.messageSending = true;
      this.sendMessageOperationCompleted = false;

      try {
        await this.$http
          .post("https://email-api.wndl.se/email/send", this.contactForm)
          .then(response => (this.response = response));
      } catch (error) {
        // Do nothing
      }

      this.sendMessageOperationCompleted = true;
      this.messageSending = false;
    }
  },
  computed: {
    isSendingMessage: function() {
      return this.messageSending;
    },
    isSendMessageOperationCompleted: function() {
      return this.sendMessageOperationCompleted;
    },
    isMessageSentSuccessfully: function() {
      return this.response !== null && this.response.data === true;
    }
  }
};
</script>

<style scoped lang="scss">
.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: 10px;
  margin: auto auto 15px auto;

  border: 1px solid var(--dark-accent);
  background-color: var(--light-shades);
  border-radius: 10px;

  &__input {
    padding: 12px 20px;
    margin-bottom: 10px;

    border: none;
    border-bottom: 3px solid var(--light-accent);
    background-color: transparent;
    transition: 0.25s;

    &:focus {
      border-bottom: 3px solid var(--main-brand-color);
      outline: none;
    }
  }

  &__button {
    padding: 5px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: var(--main-brand-color);
    font-size: 1.5em;
    color: var(--light-shades);
    align-content: center;
    cursor: pointer;

    &:hover {
      background-color: var(--dark-accent);
    }

    &:disabled {
      background-color: var(--light-accent);
    }
  }
}

.snackbar {
  width: 90%;
  max-width: 400px;
  margin: 15px auto 15px auto;
  padding: 15px 0 15px 0;
  border-radius: 5px;

  text-align: center;
  color: var(--light-shades);
}

.success {
  background-color: var(--success);
}

.failure {
  background-color: var(--failure);
}
</style>
