<template>
  <section id="coworkers" class="coworkers">
    <Coworker
      v-for="(coworker, index) in coworkers"
      :key="index"
      :coworker="coworker"
      :imageLeft="isOdd(index)"
    />
  </section>
</template>

<script>
import Coworker from "./Coworker.vue";

export default {
  name: "Coworkers",
  components: {
    Coworker
  },
  methods: {
    isOdd(number) {
      return number % 2 == 1;
    }
  },
  data() {
    return {
      coworkers: [
        {
          name: "Sten",
          role: "Senior Consultant",
          emailAddress: "sten.wendel",
          emailhost: "quendrox.com",
          linkedin: "https://www.linkedin.com/in/sten-wendel-34b411/",
          picturePath: "images/coworkers/quendrox-sten.png"
        },
        {
          name: "Fredrik",
          role: "Webdeveloper",
          emailAddress: "fredrik.wendel",
          emailhost: "quendrox.com",
          linkedin: "https://www.linkedin.com/in/fredrik-wendel",
          picturePath: "images/coworkers/quendrox-fredrik.png"
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.coworkers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & * {
    margin: 5px;
  }
}
</style>
